import styles from './page.module.css'
import { useDropzone } from "react-dropzone"
import {FC, forwardRef, useEffect, useRef, useState} from "react";

interface Follower {
  username: string
  link: string
}

interface Result {
  youDontFollow: Follower[]
  doesntFollowYou: Follower[]
}

export function App() {

  const resultsRef = useRef<HTMLHeadingElement>(null)
  const [followerFile, setFollowerFile] = useState<File>()
  const [followingFile, setFollowingFile] = useState<File>()
  const [error, setError] = useState<string>()
  const [result, setResult] = useState<Result>()
  const { getRootProps: oldGetRootProps } = useDropzone({ onDrop: droppedFiles => setFollowerFile(droppedFiles[0]) })
  const { getRootProps: newGetRootProps } = useDropzone({ onDrop: droppedFiles => setFollowingFile(droppedFiles[0]) })

  useEffect(() => {
    setError(undefined)
    const compareAsync = async () => {
      try {
        const result = await compare(followerFile, followingFile)
        setResult(result)
      } catch (e: any) {
        console.log(e )
        setError('message' in e && typeof e.message === 'string' ? e.message : 'An error occurred')
      }
    }
    void compareAsync()
  }, [followerFile, followingFile])

  useEffect(() => {
    if (result != null) {
      resultsRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [result])

  return (
    <main className={styles.main}>
      <h1>Who Follows You Back?</h1>
      <ul className={styles.steps}>
        <li className={styles.step}>
          <a href={'https://www.google.com/search?q=how%20to%20download%20your%20instagram%20data'} target={"_blank"} rel="noreferrer">
            Download your Instagram data
          </a>
        </li>
        <li className={styles.step}>
          Look for files named <code className={styles.code}>followers</code> and <code className={styles.code}>following</code> (or similar) in the folder <code className={styles.code}>followers_and_following</code>
        </li>
        <li className={styles.step}>
          Add the files to the boxes (drag and drop them, or click the box)
        </li>
        <li className={styles.step}>
          If it doesn't work, try downloading it as HTML instead of JSON, or vice versa
        </li>
      </ul>
      <div className={styles.row}>
        <div {...oldGetRootProps()} className={styles.upload}>
          {followerFile?.name ?? "Add your followers file here"}
        </div>
        <div {...newGetRootProps()} className={styles.upload}>
          {followingFile?.name ?? "Add your following file here"}
        </div>
      </div>
      <p className={styles.details}>(The comparison is run only on your computer, files are not uploaded anywhere)</p>
      <p className={styles.lillian}>{'<3 Lillian'}</p>
      {error != null && <p className={styles.error}>{error}</p>}
      {result != null && <ResultView ref={resultsRef} result={result}/>}
    </main>
  )
}

const ResultView = forwardRef<HTMLHeadingElement, { result: Result }>(({ result }, ref) => {
  const { youDontFollow, doesntFollowYou } = result
  return (
    <div className={styles.column}>
      <h2 ref={ref} className={styles.resTitle}>Results</h2>
    <div className={styles.row}>
      <div className={styles.column}>
        <span className={styles.colTitle}>{youDontFollow.length > 0 ? "You don't follow them" : "You follow everyone"}</span>
        {youDontFollow.map(f => <FollowerView follower={f}/>)}
      </div>
      <div className={styles.column}>
        <span className={styles.colTitle}>{doesntFollowYou.length > 0 ? "They don't follow you" : "Everyone follows you"}</span>
        {doesntFollowYou.map(f => <FollowerView follower={f}/>)}
      </div>
    </div>
    </div>
  )
})

const FollowerView: FC<{ follower: Follower }> = ({ follower }) => {
  return <a href={follower.link} key={follower.username}>{follower.username}</a>
}

const compare = async (followerFile?: File, followingFile?: File): Promise<Result | undefined> => {
  const followers = followerFile && await getUsernamesFromFile(followerFile, "followers")
  const following = followingFile && await getUsernamesFromFile(followingFile, "following")
  if (followers == null || following == null) return undefined
  const followerUsernames = new Set(followers.map(f => f.username))
  const followingUsernames = new Set(following.map(f => f.username))
  const doesntFollowYou = following.filter(f => !followerUsernames.has(f.username))
  const youDontFollow = followers.filter(f => !followingUsernames.has(f.username))
  return { doesntFollowYou, youDontFollow }
}

const getUsernamesFromFile = async (file: File, name: string): Promise<Follower[]> => {
  const fileContent = await read(file)
  let followers
  if (file.type.includes("json")) {
    followers = parseAsJson(fileContent, name)
  } else if (file.type.includes("html")) {
    followers = parseAsHtml(fileContent, name)
  } else {
    throw new Error(`Unsupported format for ${name} file, should be .html or .json`)
  }
  return followers.sort((a: Follower, b: Follower) => a.username.localeCompare(b.username));
}

const parseAsJson = (fileContent: any, name: string): Follower[] => {
  try {
    let json = JSON.parse(fileContent);
    if (!Array.isArray(json)) {
      json = json['relationships_following']
    }
    return json.map((value: any)  => {
      const entry = value["string_list_data"][0]
      return { username: entry["value"], link: entry["href"] }
    })
  } catch (e) {
    throw new Error(`Couldn't parse the ${name} .json file, try using the .html file instead`)
  }
}

const parseAsHtml = (fileContent: any, name: string): Follower[] => {
  try {
    const doc = document.createElement("html");
    doc.innerHTML = fileContent;
    return Array.from(doc.getElementsByTagName("a")).map((entry: any) => ({ username: entry["innerText"], link: entry["href"] }))
  } catch (e) {
    throw new Error(`Couldn't parse the ${name} .html file, try using the .json file instead`)
  }
}

const read = async (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (event) => resolve(event.target?.result);
  reader.onerror = reject;
  reader.readAsText(file);
});
